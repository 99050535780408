module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shawn Boulet - Sound Designer, Composer, Pianist","short_name":"Shawn Boulet","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/icons/icon_512x512.png","icons":[{"src":"/images/icons/icon_16x16.png","sizes":"16x16","type":"image/png"},{"src":"/images/icons/icon_32x32.png","sizes":"32x32","type":"image/png"},{"src":"/images/icons/icon_48x48.png","sizes":"48x48","type":"image/png"},{"src":"/images/icons/icon_57x57.png","sizes":"57x57","type":"image/png"},{"src":"/images/icons/icon_60x60.png","sizes":"60x60","type":"image/png"},{"src":"/images/icons/icon_72x72.png","sizes":"72x72","type":"image/png"},{"src":"/images/icons/icon_76x76.png","sizes":"76x76","type":"image/png"},{"src":"/images/icons/icon_96x96.png","sizes":"96x96","type":"image/png"},{"src":"/images/icons/icon_114x114.png","sizes":"114x114","type":"image/png"},{"src":"/images/icons/icon_120x120.png","sizes":"120x120","type":"image/png"},{"src":"/images/icons/icon_144x144.png","sizes":"144x144","type":"image/png"},{"src":"/images/icons/icon_152x152.png","sizes":"152x152","type":"image/png"},{"src":"/images/icons/icon_180x180.png","sizes":"180x180","type":"image/png"},{"src":"/images/icons/icon_192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icons/icon_256x256.png","sizes":"256x256","type":"image/png"},{"src":"/images/icons/icon_512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6dba642768db2176ffbea6a0e79fb357"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
